import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import media from "styled-media-query";
import Footer from "../components/footer.js";
import Header from "../components/Header";
import Layout from "../components/layout";
import Text, { Section } from "../components/Theme";
import IntroSectionBackground from "../images/background__shop.jpg";

const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);

    Section {
        min-height: calc(60vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;
    }

    .intro {
        &__category {
            text-transform: uppercase;
            border: 1px solid var(--white);
            padding: 4px 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
`;

const SectionGrey = styled.div`
    background-color: var(--off-white);
`;

const ShopButtons = styled(Section)`
    padding: 0;
    padding-bottom: 60px;
    min-width: 240px;

    .shopButton {
        background-color: var(--black);
        width: 100%;
        padding: 0;

        button {
            border: 1px solid;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            color: var(--white);
            background-color: var(--black);
            letter-spacing: 0.125rem;
        }
    }
`;

const ProductCategoriesContainer = styled.div``;
const ProductCategories = styled(Section)``;

// const ProductsHeroImg = styled.div`
//     grid-column: 7/12;
// `;

// const ProductGridHeaders = styled(Text)`
//   margin-bottom: 40px;
// `;
const HeroContainer = styled.div`
    background-color: #000;
    width: 100%;
    height: 100vh;

    .slider-frame {
        width: 100%;
    }

    .intro-section-gradient {
        height: 100%;
        width: 100%;
        position: absolute;
        // z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0,0,0);
        background: radial-gradient(circle, rgba(0,0,0,0) 53%, rgba(0,0,0,0.5) 100%);
    }

    .mobile-padding__bottom {
        ${media.lessThan("large")`
            padding-bottom: 60px;
        `}
    }
`;

const CarouselFrame = styled(Carousel)`
    width: 100%;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }
`;

const HeroGrid = styled.div`
    .hero-inner {
        display: flex;
        min-height: calc(100vh - 100px);
        z-index: 9998;
        align-items: flex-end;
        color: var(--white);

        ${media.lessThan("large")`
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: calc(100% - 120px);
        `}

        ${media.lessThan("medium")`
            width: calc(100% - 60px);
        `}
    }

    ${media.lessThan("large")`
            display: grid;
            padding-left: 0 !important;
            align-items: center;
            padding-right: 0 !important;
    `}

    .gatsby-image-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: -1;
        grid-column: 1/13;
        grid-row: 1;
        width: 100%;
        height: 100vh;
    }

    .section-button {
        margin-bottom: 40px;
    }

    .hero-inner__title {
        line-height: 1.15;
    }

    .hero-inner__paragraph {
        margin-bottom: 30px;
        font-size: 1.3rem;
        max-width: 550px;
    }
`;

const TwoColGrid = styled.div`
    display: grid;
    grid-column: 1/13;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;

    h2 {
        color: #000;
        margin-top: 20px;
    }

    ${media.lessThan("medium")`
        grid-column: 1/13;
    `}
`;

const ProductBlock = styled.div`
    grid-column: span 1;
    color: #fff;

    ${media.lessThan("medium")`
        grid-column: span 3;
    `}

    .block-meta {
        padding-bottom: 30px;
    }
`;

const TightTitle = styled(Text)`
    line-height: 1.15;

    ${media.lessThan("medium")`
        grid-column: span 3;
    `}
`;

const ShopCarousel = styled(Carousel)`
    width: 100%;
    grid-column: 1/13;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;
    margin-left: -20px;
    margin-right: -20px;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }

    .slider-slide {
        padding: 0 20px;
    }

    button {
        background: none;
    }

    .slider-control-centerleft {
        left: -40px !important;
    }

    .slider-control-centerright {
        right: -40px !important;
    }

    ${media.lessThan("large")`
        margin: auto;
    `}
`;

const ShopText = styled.div`
    grid-column: 4/13;
`;

const ShopContainer = styled(Section)`
    padding-bottom: 0;
    align-items: flex-end;
`;

// const FeaturedProducts = styled(Section)`
//   background-color: #eee;
// `;

//  const ProductGrid = styled.div`
//      grid-column: 2/12;
//      grid-template-columns: 1fr 1fr 1fr;
//      display: grid;
//      grid-column-gap: 50px;

//      h2 {

//          margin-bottom: 60px;
//      }
//      ${media.lessThan("medium")`
//         display: block;
//         grid-column: 1/13;
//     `}

//  `;

// const ProductWrapper = styled.div`
//     grid-column: span 1;
//     margin-bottom: 120px;
// `;
class PrintshopPage extends Component {
    state = {
        contentColor: "#ffffff",
        markers: [],
        center: { lat: 37.759703, lng: -122.428093 },
        pageWidth: 1,
    };

    componentWillMount() {
        this.slides = this.props.data.allDatoCmsPrintshopPage.edges[0].node.heroSlider;
        this.setState({ contentColor: this.slides[0].color });
    }    

    componentDidMount() {
        this.measure();
        window.addEventListener('resize', this.measure.bind(this));
    }

    componentWillUnmount() {
        this.measure();
        window.addEventListener('resize', this.measure.bind(this));
    }

    measure() {
        this.setState({ pageWidth: window.outerWidth });
    }

    matchColors(color) {
        if (color == 'black') {
            color = '#303235'
        }
        return color;
    }

    sanitizeShopUrl(url) {
        url = url.split("/");
        let lastarr = url[url.length - 1];
        if (lastarr == '') {
            lastarr = url[url.length - 2];
        }
        if (lastarr == undefined) {
            lastarr = '';
        }
        return '/shop/' + lastarr;
    }

    render() {
        const { data } = this.props;
        const { node: pageData } = data.allDatoCmsPrintshopPage.edges[0];
        const pageIntro = data.datoCmsDailyArchive.dailyArchivePageIntro[0];
        // const { contentColor } = this.state.contentColor;
        // const { contentColor, markers, center } = this.state;
        
        return (
        <Layout parent="printshop-page">
            <div className="page-products">
            <Header content_color="black" />
                <HeroContainer>
                    <CarouselFrame
                        width="100%"
                        autoplayInterval={3000}
                        pauseOnHover
                        wrapAround
                        slideWidth={1.0}
                        initialSlidewidth={1.0}
                        // autoplay
                        renderCenterLeftControls={null}
                        renderCenterRightControls={null}
                    >
                    {pageData.heroSlider.map((block, index) => (
                        <div key={`slide-${index}`}>
                            <HeroGrid>
                                <div className="hero-inner grid-container">
                                    <div className="grid-x grid-padding-y">
                                        <div className="cell small-12">                                               
                                            {block.title && (
                                            <Text className="body-copy-medium hero-inner__title" style={{color: this.matchColors(block.color)}}>
                                                {block.title}
                                            </Text>

                                            )}
                                            {block.text && (
                                            <div
                                                className="hero-inner__paragraph"
                                                style={{color: this.matchColors(block.color)}}
                                                dangerouslySetInnerHTML={{
                                                    __html: block.text
                                                }}
                                            />
                                            )}
                                            {block.buttonLink && block.buttonText && (
                                            <Link to={block.buttonLink}>
                                                {block.color === 'black' ? (
                                                    <button className="section-button">
                                                        <span>{block.buttonText}</span>
                                                        <img
                                                            src={require("../images/icons/icon__chevron--white--right.svg")}
                                                            alt="daily overview"
                                                            className="image-icon"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button className="section-button section-button__white">
                                                        <span>{block.buttonText}</span>
                                                        <img
                                                            src={require("../images/icons/icon__chevron--right.svg")}
                                                            alt="daily overview"
                                                            className="image-icon"
                                                        />
                                                    </button>
                                                )}
                                            </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-section-gradient"></div>
                                <Img
                                    fluid={this.state.pageWidth < 629 ? block.mobileImage.fluid : block.image.fluid}
                                    loading="eager"
                                    alt={block.title}
                                    backgroundColor="#fff"
                                    placeholderStyle={{
                                        filter: "blur(10px)"
                                    }}
                                    fadeIn={false}
                                />
                            </HeroGrid>
                        </div>
                    ))}
                    </CarouselFrame>
                </HeroContainer>

                <SectionGrey>
                    <ShopContainer small className="shop-slider__intro">
                        {/* TODO: REPLACE WITH CMS FIELDS */}
                        <Text
                            sub
                            col="1/4"
                            mobileCol="1/13"
                        >
                            <img
                                src={require("../images/icons/icon__cart.svg")}
                                alt="daily overview"
                                className="image-icon__large"
                            />
                                <span className="section-title">{(pageData.shopSliderTitle) ? pageData.shopSliderTitle : 'Shop'}</span>
                        </Text>
                        <ShopText
                            col="4/13"
                            mobileCol="1/13"
                        >
                            <p className="section-lead-paragraph">Feel the Overview Effect in your home with books and prints</p>
                        </ShopText>
                    </ShopContainer>

                     <Section small className="shop-slider">
                        <ShopCarousel
                            width="100%"
                            transitionMode="scroll"
                            autoplayInterval={3000}
                            pauseOnHover
                            wrapAround
                            renderBottomCenterControls={false}
                            slidesToScroll={1}
                            slidesToShow={(this.state.pageWidth < 629) ? 1 : 4}
                            slideWidth={1.0}
                            initialSlidewidth={1.0}
                            autoplay
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide}>
                                    <img
                                        src={require("../images/icons/icon__chevron--left.svg")}
                                        alt="daily overview"
                                        className="image-icon__large"
                                    />
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide}>
                                    <img
                                        src={require("../images/icons/icon__chevron--right.svg")}
                                        alt="daily overview"
                                        className="image-icon__large"
                                    />
                                </button>
                            )}
                        >  
                            {pageData.shopPageSliderProducts.map((shop_slide, i) => (
                                <Link to={this.sanitizeShopUrl(shop_slide.overview.shopLink)} key={i}>
                                    <div className="shop-slider__slide">
                                        <div className="onebytwo">
                                            <img alt="Daily Overview" src={shop_slide.overview.image.fluid.src} />
                                        </div>
                                        <h4>{shop_slide.overview.title}</h4>
                                    </div>
                                </Link>
                            ))}
                        </ShopCarousel>
                    </Section>
                    <Section small className="shop-slider__buttons">
                        <ShopButtons>
                            <Link to={`/shop/prints`}>
                              <button className="section-button section-button__fixed-width">
                                  <span>Shop All Prints</span>
                                  <img
                                      src={require("../images/icons/icon__chevron--white--right.svg")}
                                      alt="daily overview"
                                      className="image-icon"
                                  />
                              </button>
                            </Link>
                        </ShopButtons>
                    </Section>
                </SectionGrey>

                <ProductCategoriesContainer>
                    <ProductCategories small>
                        <TwoColGrid>
                            <ProductBlock>
                                <Link to="/custom">
                                    <Img
                                        fluid={
                                            data.datoCmsPrintshopPage
                                                .customBlockImage.fluid
                                        }
                                        alt=""
                                    />
                                    <div className="block-meta">
                                        <TightTitle sub>Create a Custom Print</TightTitle>
                                        <p className="section-paragraph">With the world in view, what will you make?</p>
                                    </div>
                                    <button className="section-button">
                                        <span>Create a Print</span>
                                        <img
                                            src={require("../images/icons/icon__chevron--white--right.svg")}
                                            alt="daily overview"
                                            className="image-icon"
                                        />
                                    </button>
                                </Link>
                            </ProductBlock>
                            <ProductBlock>
                                <Link to="/shop/books">
                                    <Img
                                        fluid={
                                            data.datoCmsPrintshopPage
                                                .booksBlockImage.fluid
                                        }
                                        alt=""
                                    />
                                    <div className="block-meta">
                                        <TightTitle sub>Overview Timelapse: How We Change The Earth</TightTitle>
                                        <p className="section-paragraph">A photographic survey of the state of change on Earth today.</p>
                                    </div>
                                    <button className="section-button">
                                        <span>Shop Books</span>
                                        <img
                                            src={require("../images/icons/icon__chevron--white--right.svg")}
                                            alt="daily overview"
                                            className="image-icon"
                                        />
                                    </button>
                                </Link>
                            </ProductBlock>
                        </TwoColGrid>
                    </ProductCategories>
                </ProductCategoriesContainer>
            </div>
            <Footer />
        </Layout>
        )
    }
}

export default PrintshopPage;

export const query = graphql`
query allDatoCmsPrintshopPage {
  allDatoCmsPrintshopPage {
    edges {
      node {
        shopPageSliderProducts {
          overview {
            id
            title
            slug
            image {
              fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            shopLink
          }
        }
        heroSlider {
          ... on DatoCmsSlide {
            model {
              apiKey
            }
            color
            title
            buttonText
            buttonLink
            text
            crop
            image {
              fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            mobileImage {
              fluid(maxWidth: 2000, imgixParams: {q: 85, dpr: 3, w: "2000", fit: "clip", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
            overview {
              title
              description
              slug
              geo {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  }
  datoCmsPrintshopPage {
    introText
    featuredShopImage {
      fluid {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        sizes
      }
    }
    printsBlockImage {
      fluid {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        sizes
      }
    }
    booksBlockImage {
      fluid {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        sizes
      }
    }
    customBlockImage {
      fluid {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        sizes
      }
    }
    introText
  }
  datoCmsDailyArchive {
    dailyArchivePageIntro {
      pageIntroButtonText
      pageIntroButtonUrl
      pageIntroDescription
      pageIntroTitle
      pageIntroFeaturedImage {
        fluid(maxWidth: 900, imgixParams: {dpr: 1, w: "900", fit: "clip", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
}
`;
